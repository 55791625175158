

const Role_Enum = {
    ROLE_ADMIN: {'raw':'ROLE_ADMIN', friendly: 'Admin'},
    ROLE_STOCK_OFFICER: {'raw':'ROLE_STOCK_OFFICER', friendly: 'Stock Officer'},
    ROLE_STOCK_MANAGER: {'raw':'ROLE_STOCK_MANAGER', friendly: 'Stock Manager'},
    ROLE_REQUEST_APPROVER: {'raw':'ROLE_REQUEST_APPROVER', friendly: 'Request Approver'},
    ROLE_EXECUTIVE: {'raw':'ROLE_EXECUTIVE', friendly: 'Executive'},
    ROLE_USER: {'raw':'ROLE_USER', friendly: 'User'},
    ROLE_REQUESTER: {'raw':'ROLE_REQUESTER', friendly: 'Requester'}
}

const role_keys = Object.keys(Role_Enum);

const role_obj_array = role_keys.map((key) => {
    return {
        name: Role_Enum[key].raw,
        friendly: Role_Enum[key].friendly
    }
})

const sideMenus = [
    {
        name: 'Dashboard',
        icon: 'ni ni-shop text-primary text-sm opacity-10',
        to: '/dashboard',
        roles: [Role_Enum.ROLE_ADMIN.raw, Role_Enum.ROLE_USER.raw, Role_Enum.ROLE_REQUESTER.raw, Role_Enum.ROLE_STOCK_OFFICER.raw, Role_Enum.ROLE_STOCK_MANAGER.raw, Role_Enum.ROLE_REQUEST_APPROVER.raw, Role_Enum.ROLE_EXECUTIVE.raw],
        children: []
    },
    {
        name: 'Customers',
        icon: 'ni ni-shop text-primary text-sm opacity-10',
        to: '/customers',
        roles: [Role_Enum.ROLE_ADMIN.raw, Role_Enum.ROLE_EXECUTIVE.raw],
        children: []
    },
    {
        name: 'Inventory Management',
        icon: 'ni ni-shop text-primary text-sm opacity-10',
        to: '#',
        roles: [Role_Enum.ROLE_STOCK_OFFICER.raw, Role_Enum.ROLE_STOCK_MANAGER.raw],
        children: [
            {
                name:"Inventory Metadata",
                icon:"ni ni-shop text-primary text-sm opacity-10",
                to:'/inventory-metadata',
                roles:[Role_Enum.ROLE_STOCK_MANAGER.raw, Role_Enum.ROLE_STOCK_OFFICER.raw],
                children: []
            },
            {
                name:"Items",
                icon:"ni ni-shop text-primary text-sm opacity-10",
                to:'/items',
                roles:[Role_Enum.ROLE_STOCK_MANAGER.raw, Role_Enum.ROLE_STOCK_OFFICER.raw],
                children: []
            },
            // {
            //     name: 'Variants',
            //     icon: 'ni ni-shop text-primary text-sm opacity-10',
            //     to: '/item-inventories',
            //     roles: [Role_Enum.ROLE_STOCK_MANAGER.raw, Role_Enum.ROLE_STOCK_OFFICER.raw],
            //     children: []
            // },
        ]
    },
    {
        name: 'Requests',
        icon: 'ni ni-shop text-primary text-sm opacity-10',
        to: '/requests',
        roles: [Role_Enum.ROLE_REQUESTER.raw, Role_Enum.ROLE_STOCK_OFFICER.raw, Role_Enum.ROLE_STOCK_MANAGER.raw, Role_Enum.ROLE_REQUEST_APPROVER.raw, Role_Enum.ROLE_EXECUTIVE.raw],
        children: []
    },
    {
        name: 'Reports',
        icon: 'ni ni-shop text-primary text-sm opacity-10',
        to: '/reports',
        roles: [Role_Enum.ROLE_REQUESTER.raw, Role_Enum.ROLE_STOCK_OFFICER.raw, Role_Enum.ROLE_STOCK_MANAGER.raw, Role_Enum.ROLE_REQUEST_APPROVER.raw, Role_Enum.ROLE_EXECUTIVE.raw],
        children:[]
    },
    {
        name: 'Users',
        icon: 'fa fa-users text-primary text-sm opacity-10',
        to: '/users',
        roles: [Role_Enum.ROLE_ADMIN.raw],
        children: []
    }  
]

export { sideMenus, Role_Enum, role_keys, role_obj_array }